import "./App.css";
import { Header } from "./components/Header.js";
import { Generate } from "./components/Generate/Generate.js";
import { Footer } from "./components/Footer.js";
import { Intro } from "./components/Intro.js";
import { Contact } from "./components/Contact.js";

function App() {
  return (
    <>
      <Header />
      <Intro />
      {/* <Podcast /> */}
      <Generate />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
