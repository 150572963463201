import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SearchIcon, CloseIcon, DropdownIcon, CheckIcon } from "./Icons.js";
import { Intro } from "./Intro.js";
import { AnalysisCard } from "./AnalysisCard.js";
import { AnalysisCardLoading } from "./AnalysisCardLoading.js";
import { AnalysisCardError } from "./AnalysisCardError.js";

export const Generate = () => {
  const {
    setError,
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm();
  const searchFieldVal = watch("searchField");

  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    item: null,
    idx: null,
  });
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  // const [isSearchActive, setIsSearchActive] = useState(false);
  const [analysis, setAnalysis] = useState({
    paintingTitle: "",
    artistFirstName: "",
    artistLastName: "",
    analysisContent: "",
  });

  const listboxRef = useRef();

  const handleSearch = async (e) => {
    const searchVal = e.target.value.toLocaleLowerCase();
    setValue("searchField", e.target.value);

    if (e.target.value === "") {
      return;
    }

    try {
      const response = await fetch(
        `https://iart-backend.onrender.com/artwork/search?user_input=${searchVal}`
      );
      const data = await response.json();
      const artworks = !!data.artworks ? data.artworks : [];
      setMenuItems(artworks);
    } catch (error) {
      console.log(error);
      setError("root.serverError", {
        type: "server",
        message: "Failed to search",
      });
    }
  };

  const handleItemClick = (item, idx) => {
    setValue("searchField", item.title);
    setSelectedItem({
      item: {
        paintingTitle: item.title,
        artistFirstName: item.artist.firstName,
        artistLastName: item.artist.lastName,
        // TODO: Filter which analysis to display when we will have multiples
        analysisContent: item.analysis[0].content,
      },
      idx,
    });
    setIsDropDownOpen(false);
  };

  const onSubmit = async () => {
    if (selectedItem.item !== null) {
      setAnalysis({
        paintingTitle: selectedItem.item.paintingTitle,
        artistFirstName: selectedItem.item.artistFirstName,
        artistLastName: selectedItem.item.artistLastName,
        analysisContent: selectedItem.item.analysisContent,
      });
      return;
    }

    try {
      const response = await fetch(`https://iart-backend.onrender.com/analysis`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userInput: searchFieldVal }),
      });
      const data = await response.json();
      setAnalysis({
        paintingTitle: data.paintingTitle,
        artistFirstName: data.artistFirstName,
        artistLastName: data.artistLastName,
        analysisContent: data.analysis,
      });
    } catch (error) {
      console.log(error);
      setError("root.serverError", {
        type: "server",
        message: "Failed to submit",
      });
    }
  };

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".label-button")) setIsDropDownOpen(false);
    };
  }, []);

  return (
    <section className="py-16">
      <div className="max-w-screen-xl mx-auto px-4 text-center md:px-8 mb-8">
        <Intro />
        <div
          id="generate"
          className="relative max-w-lg px-4 text-base mx-auto mt-8"
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col sm:flex-row items-center"
          >
            <div className="flex-grow relative mb-4 sm:mb-0">
              <div className="label-button flex items-center mx-auto gap-1 px-2 border rounded-lg">
                <SearchIcon />
                <input
                  {...register("searchField")}
                  type="text"
                  placeholder="Type to search"
                  className="flex-grow pl-8 pr-4 py-2 text-sm sm:text-base text-gray-500 bg-transparent rounded-md outline-none"
                  onChange={handleSearch}
                  onFocus={() => {
                    setIsDropDownOpen(true);
                    setAnalysis({
                      paintingTitle: "",
                      artistFirstName: "",
                      artistLastName: "",
                      analysisContent: "",
                    })
                    clearErrors("root")
                    // setIsSearchActive(true);
                  }}
                  // onBlur={setIsSearchActive(false)}
                  autoComplete="off"
                />
                {searchFieldVal ? (
                  <button
                    type="button"
                    onClick={() => {
                      setValue("searchField", "");
                      setSelectedItem({ item: "", idx: null });
                      setIsDropDownOpen(false);
                      setAnalysis({
                        paintingTitle: "",
                        artistFirstName: "",
                        artistLastName: "",
                        analysisContent: "",
                      })
                      clearErrors("root")
                    }}
                  >
                    <CloseIcon />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                  >
                    <DropdownIcon />
                  </button>
                )}
              </div>
              {isDropDownOpen && (
                <ul
                  ref={listboxRef}
                  className={`absolute top-full w-full mt-3 overflow-y-auto bg-white ${
                    menuItems.length > 0 ? "border rounded-md shadow-sm" : ""
                  } max-h-64`}
                  role="listbox"
                >
                  {menuItems.map((el, idx) => {
                    if (idx <= 2)
                      return (
                        <li
                          key={idx}
                          onClick={() => {
                            handleItemClick(el, idx);
                          }}
                          role="option"
                          aria-selected={selectedItem.idx === idx}
                          className={`${
                            selectedItem.idx === idx
                              ? "text-indigo-600 bg-indigo-50"
                              : ""
                          } text-left menu-el-js flex items-center justify-between px-3 py-2 cursor-default duration-150 text-gray-500 hover:text-indigo-600 hover:bg-indigo-50`}
                        >
                          {el.title}
                          {selectedItem.idx === idx ? <CheckIcon /> : ""}
                        </li>
                      );
                  })}
                </ul>
              )}
            </div>
            <button
              type="submit"
              className="w-full sm:w-auto sm:ml-4 px-5 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200"
            >
              Submit
            </button>
          </form>
          {isSubmitting && <AnalysisCardLoading />}
          {(errors.root || analysis.analysisContent === undefined) && !isDropDownOpen && <AnalysisCardError />}
          {isSubmitSuccessful && !isDropDownOpen && analysis.analysisContent !== ""  && analysis.analysisContent !== undefined && (
            <AnalysisCard
              paintingTitle={analysis.paintingTitle}
              artistFirstName={analysis.artistFirstName}
              artistLastName={analysis.artistLastName}
              analysisContent={analysis.analysisContent}
            />
          )}
        </div>
      </div>
    </section>
  );
};
