import React, { useState, useEffect } from "react";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-8">
      <div className="flex items-center justify-between py-2 border-b">
        <div className="items-center gap-x-3 md:mt-0 flex">
          <img src="/logo-iart.svg" width={80} height={20} alt="iArt logo" />
        </div>
        <div className="hidden md:flex items-center gap-x-3">
          {/* These are the buttons visible only on desktop */}
          <a
            href="https://open.spotify.com/show/130whshc1BPbGOLt6CMZd3?si=7091ec95d2024efb"
            target="_blank"
            rel="noreferrer"
            className="block px-4 py-2 text-center text-white duration-300 font-medium bg-indigo-300 rounded-lg hover:bg-indigo-500 active:bg-indigo-700 md:text-sm"
          >
            Listen on Spotify
          </a>
          <a
            href="https://podcasts.apple.com/us/podcast/palette-to-perception/id1700541465"
            target="_blank"
            rel="noreferrer"
            className="block px-4 py-2 text-center text-gray-700 duration-300 font-medium rounded-lg border hover:text-indigo-300 hover:border-indigo-300 hover:shadow md:text-sm"
          >
            Listen on Apple Podcasts
          </a>
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`md:hidden items-center gap-x-3 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {/* These are the buttons visible only on mobile when menu is open */}
        <a
          href="https://open.spotify.com/show/130whshc1BPbGOLt6CMZd3?si=7091ec95d2024efb"
          target="_blank"
          rel="noreferrer"
          className="block px-4 py-2 text-center text-white duration-300 font-medium bg-indigo-300 rounded-lg hover:bg-indigo-500 active:bg-indigo-700 md:text-sm"
        >
          Listen on Spotify
        </a>
        <a
          href="https://podcasts.apple.com/us/podcast/palette-to-perception/id1700541465"
          target="_blank"
          rel="noreferrer"
          className="block px-4 py-2 mt-3 text-center text-gray-700 duration-300 font-medium rounded-lg border hover:text-indigo-300 hover:border-indigo-300 hover:shadow sm:mt-0 md:text-sm"
        >
          Listen on Apple Podcasts
        </a>
      </div>
    </div>
  );
};
