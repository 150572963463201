import React, { useState } from "react";
import { Modal } from "./Modal.js";

export const AnalysisCard = ({
  paintingTitle,
  artistFirstName,
  artistLastName,
  analysisContent,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <article
        className="cursor-pointer max-w-md mx-auto mt-16 shadow-md hover:shadow-xl border rounded-md transition-shadow duration-300 flex h-56"
        key={paintingTitle}
        onClick={() => openModal()}
      >
        <div className="flex">
          <div className="relative w-1/3">
            <img
              src={"/book.jpg"}
              loading="lazy"
              alt={paintingTitle}
              className="w-full h-full object-cover rounded-l-md"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-40 rounded-l-md"></div>
          </div>
          <div className="p-4 flex-1 flex flex-col justify-center">
            <h3 className="text-2xl text-indigo-300 font-semibold mb-2">
              {paintingTitle}
            </h3>
            <p className="text-gray-500 text-sm">
              {artistFirstName} {artistLastName}
            </p>
          </div>
        </div>
      </article>
      <Modal
        isOpen={modalIsOpen}
        paintingTitle={paintingTitle}
        artistFirstName={artistFirstName}
        artistLastName={artistLastName}
        analysisContent={analysisContent}
        closeModal={closeModal}
      />
    </>
  );
};
