export const AnalysisCardLoading = () => {
  return (
      <div className="max-w-md mx-auto mt-24 shadow-md hover:shadow-xl border rounded-md transition-shadow duration-300 flex h-56 animate-pulse space-y-3">
        <div className="w-1/3 rounded-l-md bg-gray-300"></div>
        <div className="p-4 flex-1 flex flex-col justify-center space-y-4">
            <h3 className="text-indigo-300 font-semibold">
              We are generating your analysis
            </h3>
            <p className="text-gray-600">
              Please bear with us, this can take a minute.
            </p>
        </div>
      </div>
  );
};