export const AnalysisCardError = () => {
  return (
    <article className="cursor-pointer max-w-md mx-auto mt-24 shadow-md hover:shadow-xl border rounded-md transition-shadow duration-300 flex h-56 bg-red-50">
      <div className="flex">
        <div className="relative w-1/3">
          <img
            src={"/error-image.jpeg"}
            loading="lazy"
            alt="Error"
            className="w-full h-full object-cover rounded-l-md"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-40 rounded-l-md"></div>
        </div>
        <div className="p-4 flex-1 flex flex-col justify-center">
          <h3 className="text-2xl text-red-600 font-semibold mb-2">
            Sorry! An error occurred.
          </h3>
          <p className="text-gray-500 text-sm">
            Please reload the page and try again or contact support.
          </p>
        </div>
      </div>
    </article>
  );
};
