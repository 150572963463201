export const Contact = () => {
  return (
    <section
      className="relative overflow-hidden bg-gray-900 py-16 my-16 sm:py-20 flex items-center justify-center"
      style={{
        backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/a/a3/Joseph_Mallord_William_Turner_%281775-1851%29_-_Sun_Setting_over_a_Lake_-_N04665_-_National_Gallery.jpg")`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className="absolute inset-0"
        style={{
          background: "rgba(0, 0, 0, 0.4)",
        }}
      ></div>
      <div className="relative max-w-xl text-center sm:text-left">
        <h2 className="text-white text-3xl font-semibold sm:text-4xl">
          Interested in our <span className="text-indigo-300">solution</span> ?
        </h2>
        <p className="mt-3 text-gray-300">
          Don't hesitate to drop us an email if you have any question or enquiry
          about our product :)
        </p>
        <div className="flex-none mt-6">
          <a
            className="py-2.5 px-4 sm:text-left rounded-full duration-300 inline-flex items-center gap-x-2 font-medium text-sm text-white bg-indigo-300 hover:bg-indigo-500 text-white active:bg-blue-700 "
            href="mailto: theiartdimension@gmail.com"
          >
            Contact us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};
