export const Modal = ({
  isOpen,
  closeModal,
  paintingTitle,
  artistFirstName,
  artistLastName,
  analysisContent,
}) => {
  return isOpen ? (
    <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center min-h-screen bg-black bg-opacity-50">
      <div className="relative w-full h-full sm:w-4/5 sm:h-4/5 md:w-3/4 md:h-3/4 bg-white rounded-md shadow-lg flex flex-col justify-between">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-between p-4 border-b">
            <h4 className="text-lg font-medium text-gray-800">
              <span className="text-indigo-300">In-depth </span>
              {`commentary of ${paintingTitle} by ${artistFirstName} ${artistLastName}`}
            </h4>
            <button
              className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mx-auto"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-grow overflow-y-auto p-4 mt-3 text-[15.5px] leading-relaxed text-gray-500">
          <div dangerouslySetInnerHTML={{ __html: analysisContent }} />
        </div>
        <div className="flex-shrink-0 flex items-center gap-3 p-4 mt-5 border-t">
          <button
            className="px-6 py-2 text-white bg-indigo-600 rounded-md outline-none ring-offset-2 ring-indigo-600 focus:ring-2"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
