import { SectionWrapper } from "./SectionWrapper.js";

export const Intro = () => {
  return (
    <>
      <section
        className="relative min-h-screen flex items-center justify-center bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url("https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/The_Fighting_Temeraire%2C_JMW_Turner%2C_National_Gallery.jpg/1920px-The_Fighting_Temeraire%2C_JMW_Turner%2C_National_Gallery.jpg")`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-9 text-center space-y-4 px-4">
          <h1 className="text-white font-bold text-4xl md:text-5xl">
            Enhance Your Museum Experience with
            <span className="text-indigo-300"> iArt</span>
          </h1>
          <p className="text-gray-300 max-w-xl mx-auto leading-relaxed">
            Turn every museum visit into a deep dive using AI. Generate & listen
            to inspiring analysis of any artwork from any museum in the world.
          </p>

          <div className="mt-12 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
            <a
              href="#generate"
              className="px-10 py-3.5 w-full bg-indigo-300 duration-300 hover:bg-indigo-500 text-white text-center rounded-md shadow-md block sm:w-auto"
            >
              Generate
            </a>
            <a
              href="https://open.spotify.com/show/130whshc1BPbGOLt6CMZd3?si=9e9160ece7094a51"
              target="_blank"
              rel="noreferrer"
              className="px-10 py-3.5 w-full text-gray-300 text-center border border-gray-300 rounded-md duration-300 hover:text-indigo-300 hover:border-indigo-300 hover:shadow block sm:w-auto"
            >
              Check on Spotify
            </a>
          </div>
        </div>
      </section>

      <SectionWrapper>
        <div className="custom-screen text-center">
          <div className="max-w-xl mx-auto">
            <h2 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
              We help you get more from a{" "}
              <span className="text-indigo-300">painting</span>
            </h2>
            <p className="mt-3 text-gray-600">
              Sometimes it's nice to get some more information about a work of
              art to truly enhance our understanding and perception of it. iArt
              offers you a wealth of painting analyses, designed to deepen your
              appreciation of an artist's full range of talent. Listen to some
              of our free sample guides just below.
            </p>
          </div>
        </div>

        <section className="mt-12 mx-auto px-4 max-w-screen-xl md:px-8">
          <div className="mt-12 grid gap-2 sm:grid-cols-2 lg:grid-cols-3">
            {posts.map((items, key) => (
              <article
                className="w-full mt-4 pb-2 shadow-lg border rounded-md duration-300 hover:shadow-sm"
                key={key}
              >
                <a href={items.href} target="_blank" rel="noreferrer">
                  <img
                    src={items.img}
                    loading="lazy"
                    alt={items.title}
                    className="w-full h-48 rounded-t-md object-cover"
                  />
                  <div className="flex items-center mt-2 pt-3 ml-4 mr-2">
                    <div className="flex items-center gap-x-3">
                      <div className="bg-white w-14 h-14 border rounded-full flex items-center justify-center">
                        <img
                          src={items.authorLogo}
                          className="w-full h-full rounded-full object-cover"
                          alt={items.authorLogo}
                        />
                      </div>
                      <div className="text-left">
                        <span className="block text-sm text-indigo-600 font-medium">
                          {items.authorName}
                        </span>
                        <h3 className="text-base text-gray-800 font-semibold mt-1">
                          {items.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                </a>
              </article>
            ))}
          </div>
        </section>
      </SectionWrapper>

      <div className="custom-screen">
        <div className="py-14">
          <div className="max-w-screen-xl mx-auto px-4 md:px-8">
            <h3 className="font-semibold text-sm text-indigo-300 text-center">
              EXPLORE THE MOST FAMOUS MUSEUMS IN THE WORLD
            </h3>
            <div className="mt-6">
              <ul className="flex gap-x-8 gap-y-8 flex-wrap items-center justify-center md:gap-x-12 lg:gap-x-16">
                <ul className="inline-grid grid-cols-1 gap-x-8 gap-y-8 md:gap-x-12 lg:gap-x-16 md:grid-cols-3 lg:grid-cols-4 content-center">
                  {logos.map((item, idx) => (
                    <li key={idx} className="self-center mb-4 md:mb-0">
                      <img
                        src={item.src}
                        className="w-56 sm:w-56 md:w-64 lg:w-68"
                        viewBox="0 0 164 48"
                        fill="none"
                        alt={item.alt}
                      />
                    </li>
                  ))}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const logos = [
  {
    src: "/louvre.svg",
    alt: "louvre",
  },
  {
    src: "/national_gallery.svg",
    alt: "national_gallery",
  },
  {
    src: "/prado.svg",
    alt: "prado",
  },
  {
    src: "/rijksmuseum.svg",
    alt: "rijksmuseum",
  },
];

const posts = [
  {
    title: "The Milkmaid",
    img: "https://upload.wikimedia.org/wikipedia/commons/2/20/Johannes_Vermeer_-_Het_melkmeisje_-_Google_Art_Project.jpg",
    authorLogo:
      "https://campusdata.uark.edu/resources/images/articles/2014-03-27_01-28-30-PMJanVermeerSelfPortrait1654.jpg",
    authorName: "Johannes Vermeer",
    date: "Jan 4 2022",
    href: "https://open.spotify.com/episode/2jj7yTXEgEv0FMwkvKDPxK?si=8730c16124f841c8",
  },
  {
    title: "The Night Watch",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/The_Night_Watch_-_HD.jpg/1200px-The_Night_Watch_-_HD.jpg",
    authorLogo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Rembrandt_van_Rijn_-_Self-Portrait_-_Google_Art_Project.jpg/1200px-Rembrandt_van_Rijn_-_Self-Portrait_-_Google_Art_Project.jpg",
    authorName: "Rembrandt van Rijn",
    href: "https://open.spotify.com/episode/43D5iMf3sNIHg8dKbfY5vH?si=2c851be88a9b4aeb",
  },
  {
    title: "The windmill at Wijk Bij Duurstede",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/The_Windmill_at_Wijk_bij_Duurstede_1670_Ruisdael.jpg/1241px-The_Windmill_at_Wijk_bij_Duurstede_1670_Ruisdael.jpg",
    authorLogo:
      "https://www.artble.com/imgs/b/8/4/615943/jan_van_goyen_300px.jpg",
    authorName: "Jacob van Ruisdael",
    href: "https://open.spotify.com/episode/48hgwYOAIPAs6hct3bAf5a?si=8aebba7bf16b4762",
  },
];
