export const Intro = () => (
  <div className="max-w-xl space-y-3 md:mx-auto">
    <h3 className="text-indigo-300 font-semibold">
      Want to learn more about a specific artwork ?
    </h3>
    <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
      Look up <span className="text-indigo-300">any</span> painting in the world
    </p>
    <p className="text-gray-600">
      Use our research tool to generate an in-depth analysis of any artworks in
      your museum of choice. Just type the painting & artist name in the search
      bar and we will generate a commentary just for you.
    </p>
  </div>
);
